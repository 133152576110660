.events_pic {
  @media (min-width: $large_min_width) {
    top: 18vh;
  }
}

.events_list ul {
  margin-top: 18px;

  @media (min-width: $small_min_width) {
    margin-top: 20px;
  }
}
