.template-homepage {
  background: url(../images/background.png);

  // After reloading the page, homepage always starts from the top of the layout
  // so that the welcome animation is always visible
  &.is-fixed {
    position: fixed;
    width: 100%;
  }
}

.main_homepage {
  padding-top: 61px;

  @media (min-width: $small_min_width) {
    padding-top: 68px;
  }

  @media (min-width: $large_min_width) {
    padding-top: 151px;
  }
}

.logo_container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo_container-logo {
  max-width: 100%;
  width: 245px;

  @media (min-width: $small_min_width) {
    width: 366px;
  }
}

.transparent_logo {
  opacity: 0;
}

.content_homepage {
  @media (max-width: $xsmall_max_width) {
    padding-top: 61px;
    padding-bottom: calc(68px + 40px);
  }

  // This values taking in consideration of the footer-scene-hill height
  @media (min-width: $small_min_width) {
    padding-top: 68px;
    padding-bottom: calc(68px + 45px);
  }

  @media (min-width: $medium_min_width) {
    padding-top: 101px;
    padding-bottom: calc(101px + 45px);
  }

  @media (min-width: $large_min_width) {
    padding-top: 121px;
    padding-bottom: calc(121px + 50px);
  }

  @media (min-width: $xlarge_min_width) {
    padding-top: 136px;
    padding-bottom: calc(136px + 50px);
  }
}
