// Reduce the welcome div height to 0 in order to avoid that it could overlap with the main elements
@keyframes welcome-hidden {
  from {
    height: 100vh;
  }

  to {
    height: 0vh;
  }
}

// Hide the rainbow stripes
@keyframes welcome-stripes-hidden {
  from {
    height: 100vh;
  }

  to {
    height: 0vh;
  }
}

// Shift the logo to the original position on mobile devices
@keyframes welcome-logo-shifted-xs {
  from {
    transform: translate3d(0, calc(50vh - 70px), 0);
  }

  to {
    transform: translate3d(0, calc(166px - 105px), 0);
  }
}

// Shift the logo to the original position on tablet devices
@keyframes welcome-logo-shifted-s {
  from {
    transform: translate3d(0, calc(50vh - 110px), 0);
  }

  to {
    transform: translate3d(0, calc(173px - 105px), 0);
  }
}

// Shift the logo to the original position on laptop devices
@keyframes welcome-logo-shifted-l {
  from {
    transform: translate3d(0, calc(50vh - 110px), 0);
  }

  to {
    transform: translate3d(0, calc(256px - 105px), 0);
  }
}

// Change the header z-index to avoid that the logo could overlap the nav
@keyframes nav-icon-z-index {
  from {
    z-index: $z-header-pre-welcome;
  }

  to {
    z-index: $z-header-post-welcome;
  }
}
