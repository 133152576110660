.header {
  animation: $a-nav-icon-z-index;
  height: 0;
  position: fixed;
  width: 100%;
  z-index: $z-header-pre-welcome;

  @media (min-width: $large_min_width) {
    background: none;
    position: absolute;
  }

  &.is_nav_shown {
    height: 100vh;
  }
}

.header-nav_button {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  padding: 24px 24px 0 0;
  position: absolute;
  right: 0;
  z-index: $z-nav-icon;

  @media (min-width: $large_min_width) {
    display: none;
  }
}

.nav_icon {
  fill: $orange;
  width: 27px;
}

.nav_icon .top_square,
.nav_icon .bottom_square,
.nav_icon .middle_right_square,
.nav_icon .middle_left_square {
  transform: translate3d(0, 0, 0);
  transition: $t-nav-icon-transformed;
}

.nav_icon .top_square {
  &.is_nav_shown {
    transform: translate3d(75%, 75%, 0);
  }
}

.nav_icon .bottom_square {
  &.is_nav_shown {
    transform: translate3d(-75%, -75%, 0);
  }
}

.nav_icon .middle_right_square {
  &.is_nav_shown {
    transform: translate3d(-75%, 75%, 0);
  }
}

.nav_icon .middle_left_square {
  &.is_nav_shown {
    transform: translate3d(75%, -75%, 0);
  }
}
