.welcome {
  animation: $a-welcome-hidden;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $z-welcome;
}

.welcome-stripes {
  display: flex;
  height: 100%;
}

.welcome-stripes-stripe {
  height: 100vh;
  width: 20%;

  &.red_stripe {
    animation: $a-welcome-red-stripe-hidden;
    background-color: $wild_watermelon;
  }

  &.yellow_stripe {
    animation: $a-welcome-yellow-stripe-hidden;
    background-color: $dolly;
  }

  &.green_stripe {
    animation: $a-welcome-green-stripe-hidden;
    background-color: $screamin_green;
  }

  &.blue_stripe {
    animation: $a-welcome-blue-stripe-hidden;
    background-color: $dodger_blue;
  }

  &.violet_stripe {
    animation: $a-welcome-violet-stripe-hidden;
    background-color: $heliotrope;
  }
}

.logo_welcome {
  animation: $a-welcome-logo-shifted-xs;
  position: absolute;
  top: 0;
  transform: translate3d(0, calc(50vh - 70px), 0);

  @media (min-width: $small_min_width) {
    animation: $a-welcome-logo-shifted-s;
    transform: translate3d(0, calc(50vh - 110px), 0);
  }

  @media (min-width: $large_min_width) {
    animation: $a-welcome-logo-shifted-l;
  }
}
