// Import normalize.css
@import "../../../node_modules/normalize.css/normalize";

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
* Remove default margins
*/

h1,
h2,
h3,
p,
figure {
  margin: 0;
}

/*
* Reset headings weight
*/

h1,
h2,
h3 {
  font-weight: normal;
}

/*
* Reset link styles
*/

a,
a:hover,
a:active,
a:visited {
  color: $blue;
  text-decoration: none;
}

/*
* Reset list styles
*/

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
