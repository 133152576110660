h1,
h2,
h3 {
  font-family: $acme;
  letter-spacing: $ls-acme;
  line-height: $lh-heading;
}

h1,
h2 {
  color: $orange;
  text-transform: uppercase;
}

h1 {
  font-size: $h1-fs;
}

h2 {
  font-size: $h2-fs;
}

h3 {
  font-size: $h3-fs;
}

p {
  font-family: $droid-sans;
  font-size: $p-fs;
}
