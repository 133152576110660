.footer {
  // This values taking in consideration of footer-scene-hill and rainbow svg heights
  margin-top: calc(-40px + -40px);

  @media (min-width: $small_min_width) {
    margin-top: calc(-45px + -45px);
  }

  @media (min-width: $large_min_width) {
    margin-top: -50px;
    position: relative;
  }
}

.footer-rainbow {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  height: 40px;

  @media (min-width: #{$small_min_width}) {
    height: 45px;
  }

  @media (min-width: #{$large_min_width}) {
    height: 0;
    position: absolute;
    right: 0;
    top: -170px;
  }

  @media (min-width: #{$xlarge_min_width}) {
    top: -240px;
  }
}

.rainbow_svg {
  width: 90px;

  @media (min-width: #{$small_min_width}) {
    width: 95px;
  }

  @media (min-width: #{$large_min_width}) {
    width: 225px;
  }

  @media (min-width: #{$xlarge_min_width}) {
    width: 300px;
  }
}

.rainbow_svg-red {
  fill: $wild_watermelon;
}

.rainbow_svg-yellow {
  fill: $dolly;
}

.rainbow_svg-green {
  fill: $screamin_green;
}

.rainbow_svg-blue {
  fill: $dodger_blue;
}

.rainbow_svg-violet {
  fill: $heliotrope;
}

.footer-scene {
  height: 50px;
  position: relative;
  overflow: hidden;

  @media (min-width: #{$small_min_width}) {
    height: 55px;
  }

  @media (min-width: #{$large_min_width}) {
    height: 60px;
  }
}

.footer-scene-hill {
  bottom: 0;
  height: 40px;
  left: 0;
  position: absolute;
  width: 200%;

  @media (min-width: #{$small_min_width}) {
    height: 45px;
  }

  @media (min-width: #{$large_min_width}) {
    height: 50px;
  }

  &::before {
    background: $green;
    border-radius: 50%;
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.25);
    content: "";
    height: 100vh;
    position: absolute;
    right: 0;
    width: 150%;
  }
}

.footer-content {
  background-color: $green;
  color: $light-grey;
  padding: 18px 24px;
}

.footer-content > .rich-text {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-content > .rich-text > p {
  font-size: 0.9rem;
}

.footer-content > .rich-text > p > a {
  color: $white;
  text-decoration: underline;
}
