.nav {
  background-image: linear-gradient(to right, #FF5970 0%, #FFF087 25%, #69FF9C 50%, #3BCCFF 75%, #9153FF 100%);
  box-sizing: border-box;
  height: 100vh;
  padding: 91px 0;
  transform: translate3d(-100%, 0, 0);
  transition: $t-nav-shown;
  visibility: hidden;

  @media (min-width: $small_min_width) {
    padding: 151px 0;
  }

  @media (min-width: $medium_min_width) and (orientation: landscape) {
    padding: 101px 0;
  }

  @media (min-width: $large_min_width) {
    background: none;
    display: flex;
    height: auto;
    justify-content: flex-end;
    padding: 27px 0;
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  @media (max-height: $xsmall_landscape_max_height) and (orientation: landscape) {
    padding: 27px 0;
  }

  &.is_nav_shown {
    transform: translate3d(0%, 0, 0);
    visibility: visible;
  }
}

.nav-list {
  align-items: center;
  color: $blue;
  display: flex;
  flex-direction: column;
  font-family: $acme;
  font-size: $nav-items-fs;
  height: 100%;
  justify-content: space-between;
  letter-spacing: $ls-acme;
  text-transform: uppercase;

  @media (min-width: $large_min_width) {
    flex-direction: row;
    font-size: 1rem;
    justify-content: space-around;
    width: 100%;
  }
}

// Add to the white smile item the same width occupied by the blue one,
// so that all items keep the same space among themselves
.nav-list li:first-child {
  @media (min-width: $large_min_width) {
    min-width: 25px;
    position: relative;
  }
}

.nav-list li a {
  @media (min-width: $large_min_width) {
    color: $light_grey;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  }
}

.template-homepage .nav-list li a {
  @media (min-width: $large_min_width) {
    color: $blue;
    text-shadow: none;
  }
}

.nav-list-home {
  align-items: center;
  display: flex;
}

.nav-list .smile {
  fill: none;
  height: 20px;
  stroke: $blue;
  stroke-linecap:round;
  stroke-width: 3;
}

.nav-list .smile-shadow {
  display: none;
  fill: none;
  height: 70px;
  left: -25px;
  position: absolute;
  stroke: $light_grey;
  stroke-linecap: round;
  stroke-width: 3px;

  @media (min-width: $large_min_width) {
    display: block;
  }
}

// Hide blue smile in all pages on laptop devices
.nav-list .smile {
  @media (min-width: $large_min_width) {
    display: none;
  }
}

// Show blue smile in the home page on laptop devices
.template-homepage .nav-list .smile {
  @media (min-width: $large_min_width) {
    display: block;
  }
}

// Hide white smile in the home page on laptop devices
.template-homepage .nav-list .smile-shadow {
  @media (min-width: $large_min_width) {
    display: none;
  }
}
