// Styles related to the cookie banner
// -----------------------------------

.cc-banner.cc-bottom,
.cc-window.cc-floating {
  background-color: $orange;
  box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.25);
  color: $light-grey;
  font-family: $droid-sans;
  z-index: 50;
}

.cc-window.cc-floating {
  text-align: center;
}

a.cc-link {
  color: $light-grey;
  opacity: 1;

  &:hover {
    color: $blue;
  }
}

.cc-btn {
  background-color: $blue;
  border-color: $blue;
  color: $light-grey;
  font-family: $acme;

  &:hover {
    color: $light-grey;
  }
}
