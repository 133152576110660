.contacts_picture {
  background-image: linear-gradient(to right, #FF5970 0%, #FFF087 25%, #69FF9C 50%, #3BCCFF 75%, #9153FF 100%);

  &::after {
    display: none;
  }
}

.contacts > * + * {
  margin-top: 27px;

  @media (min-width: #{$small_min_width}) {
    margin-top: 30px;
  }
}

#map {
  // Extra value of 20vh for parallax
  height: calc(225px + 20vh);
  opacity: .6;
  width: 100%;

  @media (min-width: #{$small_min_width}) {
    height: calc(425px + 20vh);
  }

  @media (min-width: #{$large_min_width}) {
    height: calc(525px + 20vh);
  }

  @media (min-width: #{$xlarge_min_width}) {
    height: calc(625px + 20vh);
  }

  @media (max-height: #{$xsmall_landscape_max_height}) and (orientation: landscape) {
    height: calc(250px + 20vh);
  }
}
