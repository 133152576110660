.picture {
  align-items: center;
  display: flex;
  max-height: 225px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (min-width: $small_min_width) {
    max-height: 425px;
  }

  @media (min-width: $large_min_width) {
    max-height: 525px;
  }

  @media (min-width: $xlarge_min_width) {
    max-height: 625px;
  }

  @media (max-height: $xsmall_landscape_max_height) and (orientation: landscape) {
    max-height: 250px;
  }

  &::after {
    background-image: linear-gradient(to right, #FF5970 0%, #FFF087 25%, #69FF9C 50%, #3BCCFF 75%, #9153FF 100%);
    content: '';
    height: 100%;
    left: 0;
    opacity: .5;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.picture-pic {
  height: auto;
  position: relative;
  transform: translate3d(0, 0, 0);
  width: 100%;
}

.picture-overlying_div {
  bottom: 1px;
  height: 30px;
  left: -10%;
  position: absolute;
  width: 200%;
  z-index: $z-overlying-picture;

  @media (min-width: $small_min_width) {
    height: 50px;
  }

  &::before {
    background: $light-grey;
    border-radius: 50%;
    box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.25);
    content: "";
    height: 100vh;
    position: absolute;
    right: 0;
    width: 150%;

    @media (min-width: $small_min_width) {
      background: $light_grey url(../images/background-xs.png);
      background-repeat-y: no-repeat;
    }
  }
}

// Fix a bug in Chrome mobile where a white space between
// the curved div and the bottom of the figure is shown
.fix_white_space {
  background-color: $light-grey;
  height: 2px;
  position: relative;
  top: -1px;
  width: 100%;
  z-index: $z-fix-picture-white-space;
}
