html {
  font: 18px/1.618 $droid-sans;

  @media (min-width: $small_min_width) {
    font-size: 20px;
  }

  @media (min-width: $large_min_width) {
    font-size: 24px;
  }

  @media (min-width: $xlarge_min_width) {
    font-size: 27px;
  }
}

body {
  background-color: $light_grey;
}

.background_container {
  // This values taking in consideration of the footer-scene-hill height
  background: url(../images/background.png);
  padding: calc(41px - 40px) 0 calc(41px + 40px);
  margin-top: 40px;

  @media (min-width: $small_min_width) {
    padding: calc(68px - 45px) 0 calc(68px + 45px);
    margin-top: 45px;
  }

  @media (min-width: $medium_min_width) {
    padding: calc(101px - 45px) 0 calc(101px + 45px);
  }

  @media (min-width: $large_min_width) {
    padding: calc(121px - 50px) 0 calc(121px + 50px);
    margin-top: 50px;
  }

  @media (min-width: $xlarge_min_width) {
    padding: calc(136px - 50px) 0 calc(136px + 50px);
  }

  @media (max-height: $xsmall_landscape_max_height) and (orientation: landscape) {
    padding: calc(41px - 40px) 0 calc(41px + 40px);
    margin-top: 40px;
  }
}

.content {
  padding: 0 24px;
  margin: 0 auto;

  @media (min-width: $small_min_width) {
    padding: 0 45px;
  }

  @media (min-width: $medium_min_width) {
    padding: 0 151px;
  }

  @media (min-width: $large_min_width) {
    padding: 0;
    width: 768px;
  }

  @media (min-width: $xlarge_min_width) {
    padding: 0;
    width: 960px;
  }

  & > * + * {
    margin-top: 41px;

    @media (min-width: $small_min_width) {
      margin-top: 45px;
    }
  }
}

// Add space between h1 and the underneath article
.content > h1 + article {
    margin-top: 31px;

    @media (min-width: $small_min_width) {
      margin-top: 36px;
    }
}

// Add space among articles
.content > .content-article + .content-article {
  margin-top: 61px;

  @media (min-width: $small_min_width) {
    margin-top: 68px;
  }

  @media (min-width: $large_min_width) {
    margin-top: 81px;
  }
}

// Add space among article contents
.content-article > * + * {
  margin-top: 24px;

  @media (min-width: $small_min_width) {
    margin-top: 30px;
  }

  @media (min-width: $large_min_width) {
    margin-top: 36px;
  }
}

// Add space between h2 and the underneath paragraph
.rich-text > h2 + p {
  margin-top: 18px;

  @media (min-width: $small_min_width) {
    margin-top: 20px;
  }
}
