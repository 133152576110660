.template-error > .header,
.template-error > .footer {
  display: none;
}

.error-stripes {
  display: flex;
  height: 100%;
}

.error-stripes-stripe {
  filter: grayscale(1);
  height: 100vh;
  width: 20%;

  &.red_stripe {
    background-color: $wild_watermelon;
  }

  &.yellow_stripe {
    background-color: $dolly;
  }

  &.green_stripe {
    background-color: $screamin_green;
  }

  &.blue_stripe {
    background-color: $dodger_blue;
  }

  &.violet_stripe {
    background-color: $heliotrope;
  }
}

.error-content {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%
}

.error-logo > img {
  opacity: 1;
}

.error-content-text {
  text-align: center;
  margin-top: 1.5em;
}

.error-content-text p {
  color: $blue;
  font-family: $acme;
  font-size: 1.5rem;

  &:first-child {
    text-transform: uppercase;
  }
}

.error-content-text .button {
  margin-top: 18px;
}
