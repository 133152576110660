// Avoid direct li children in order to select list that belong to rich texts
.bullet_list li {
  padding-left: 1rem;
  position: relative;

  &::before {
    background: url(../svgs/spiral.svg) no-repeat center / contain;
    content: '';
    height: 0.7rem;
    left: 0;
    // Keep the bullet centered relative to the first line of each <li>
    margin-top: 0.5rem;
    position: absolute;
    top: 0;
    width: 0.7rem;
  }
}
