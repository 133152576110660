.plan-download {
  margin-top: 27px;

  & > * + * {
    margin-top: 18px;
  }
}

.plan-download h3 {
  text-transform: uppercase;
}

.timetable-container-list,
.costs-container-service {
  margin-top: 18px;

  @media (min-width: $small_min_width) {
    margin-top: 20px;
  }
}
